import { useContext, createContext, useRef, useLayoutEffect, useState, useMemo, useCallback, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface ContextValue {
    setActive: (value: boolean) => void;
    container: HTMLElement;
}

const Context = createContext<ContextValue | null>(null);

export const createHeader = (rootId: string, className = '') => {
    // it may be helpful to track if we have an active header
    const [active, setActive] = useState(false);
    // we need to create a static DOM element for our portal
    // we will attach it to the actual root element later on
    const containerRef = useRef<HTMLElement | null>(null);

    if (!containerRef.current) {
        // create a header element
        containerRef.current = document.createElement('header');
    }

    const { current: container } = containerRef;

    if (container.className !== className) {
        // since the container is not man aged by React, we can freely update the classname on all updates
        // ensuring it's always on sync
        container.className = className;
    }

    // once the layout is ready
    // we may append the container to our root element
    useLayoutEffect(() => {
        const root = document.getElementById(rootId);

        if (root) {
            root.appendChild(container);
        } else {
            console.error(`Root element with id ${rootId} not found.`);
        }

        return () => {
            // cleanup the container from the DOM
            container.remove();
        };
    }, [container, rootId]);

    // build the context
    const context = useMemo((): ContextValue => ({ setActive, container }), [setActive, container]);

    // to make it easier to use, create a render function to wrap the react tree with the proper context
    const render = useCallback(
        (children: ReactNode) => <Context.Provider value={context}>{children}</Context.Provider>,
        [context]
    );

    return { render, active };
};

const useHeader = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useHeader must be used within a HeaderProvider');
    }

    const { container, setActive } = context;

    const render = useCallback((children: ReactNode): ReactNode => createPortal(children, container), [container]);

    useLayoutEffect(() => {
        setActive(true);

        return () => setActive(false);
    }, [setActive]);

    return render;
};

export default useHeader;
