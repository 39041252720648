import clsx from 'clsx';
import { primary } from '../../../utils/theme';

type LoaderProps = {
    height?: 'auto' | 'screen';
};

const Loader = ({ height = 'auto' }: LoaderProps) => (
    <div
        className={clsx(
            'flex items-center justify-center p-3',
            height === 'auto' && 'h-auto',
            height === 'screen' && 'h-screen'
        )}
    >
        <div
            // eslint-disable-next-line max-len
            className={`h-10 w-10 animate-spin rounded-full border-4 border-solid ${primary.borderColor} border-t-transparent`}
        />
    </div>
);

export default Loader;
